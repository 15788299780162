import { CompanyEnums } from "./../types/CompanySchema";
import { SignatureData } from "../types/SignatureData";

const ejsTemplates = require.context("../assets/templates/");

const getEJS = (key: CompanyEnums) => {
  try {
    return ejsTemplates<(config: unknown) => string>(`./${key}.ejs`);
  } catch (e) {
    console.log(e);
  }
};

const templateFileHeader = "data:text/html;charset=utf-8,";

const generateTemplate = async (
  data: SignatureData
): Promise<string | undefined> => {
  const { company, ...options } = data;

  const ejsFileName = getEJS(company!)!;

  try {
    const parsedTemplate = ejsFileName(options);

    return templateFileHeader + encodeURIComponent(parsedTemplate);
  } catch (e) {
    console.log(e);

    return undefined;
  }
};

export { generateTemplate };
