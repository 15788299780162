import React from "react";
import { SelectProps } from "../../types/Input";
import InputBase from "./InputBase";

import chevronDown from "../../assets/icons/icon-24-chevron-down.svg";

const Select: React.FC<SelectProps> = ({
  label,
  placeholder,
  options,
  value,
  onChange,
  helper,
  id,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    onChange(e.target.value);

  return (
    <InputBase label={label} helper={helper} id={id}>
      <div className="relative w-96 cursor-pointer">
        <select
          className="border-2 px-4 py-3 w-96 rounded-lg border-ligthGray appearance-none cursor-pointer"
          value={value}
          onChange={handleChange}
        >
          <option selected={!value} disabled>
            {placeholder}
          </option>
          {options.map((option) => (
            <option selected={value === option.id} value={option.id}>
              {option.value}
            </option>
          ))}
        </select>
        <img
          className="absolute right-4 top-2/4 -translate-y-2/4 pointer-events-none"
          src={chevronDown}
          alt="Arrow down"
        />
      </div>
    </InputBase>
  );
};

export default Select;
