import { useState } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../utils/routes";
import { JobPosition } from "../../../types/WorkPosition";
import deleteIcon from "../../../assets/icons/delete.svg";
import editIcon from "../../../assets/icons/edit.svg";

type CardProps = {
  position: JobPosition;
  deletePosition: () => void;
};

const Card = ({ position, deletePosition }: CardProps): JSX.Element => {
  const [showActions, setShowActions] = useState<boolean>(false);
  const formattedDate = (date: string) => {
    return new Date(date).toLocaleString().split(",")[0];
  };
  return (
    <div
      onMouseEnter={() => {
        setShowActions(true);
      }}
      onMouseLeave={() => setShowActions(false)}
      className="w-full border-2 border-white p-2 mb-2 bg-white rounded-lg hover:border-black"
    >
      <div className="w-full min-h-[3rem] flex flex-row items-center justify-start">
        <div className="w-[10%]">{formattedDate(position.created_at)}</div>
        <div className="w-[25%] flex flex-row">
          {position.locations.map((location, idx) => (
            <p className="pr-1" key={idx}>
              {location.city}
            </p>
          ))}
        </div>
        <div className="w-[10%]">{position.company.name}</div>
        <div className="w-[15%]">{position.category.name}</div>
        <div className="w-[35%]">{position.title}</div>
        {showActions && (
          <div className="flex flex-row">
            <button
              className="mr-2 p-2 border-2 rounded-md"
              onClick={deletePosition}
            >
              <img src={deleteIcon} alt="Elimina" />
            </button>
            <Link
              className="mr-2 p-2 border-2 rounded-md"
              to={ROUTES.EDIT_POSITION.replace(":id", `${position.id}`)}
            >
              <img src={editIcon} alt="Modifica" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
