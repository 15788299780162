import React from "react";
import { Header } from "./components";

type Props = {
  children: React.ReactNode;
};

/**
 *
 * Layout.tsx
 * Component to arrange an Header and the main content
 *
 */
export const Layout = ({ children }: Props) => (
  <div>
    <Header />
    <main>{children}</main>
  </div>
);
