import React from "react";
import { InputBaseProps } from "../../types/Input";

const InputBase: React.FC<InputBaseProps> = ({
  id,
  label,
  helper,
  children,
}) => {
  return (
    <div className="flex flex-col">
      <label className="text-2xl font-medium leading-8 mb-2" htmlFor={id}>
        {label}
      </label>
      {helper ? <div className="text-base leading-6 my-2">{helper}</div> : null}
      {children}
    </div>
  );
};

export default InputBase;
