import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { InputProps } from "../../types/Input";
import InputBase from "./InputBase";

const RichTextArea: React.FC<InputProps> = ({
  label,
  id,
  onChange,
  value,
  helper,
  placeholder,
}) => {
  return (
    <InputBase label={label} id={id} helper={helper}>
      <div className="bg-white">
        <ReactQuill
          theme="snow"
          value={value as string}
          onChange={onChange}
          placeholder={placeholder}
          id={id}
        />
      </div>
    </InputBase>
  );
};

export default RichTextArea;
