import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import PacmanLoader from "react-spinners/PacmanLoader";
import {
  getCategories,
  getCompanies,
  getLocations,
  updatePosition,
  getPositionById,
} from "../../utils/position";
import {
  Checkboxes,
  InputText,
  Select,
  RadioGroup,
  RichTextArea,
} from "../../components/Input";
import * as ROUTES from "../../utils/routes";
import {
  Location,
  Company,
  Category,
} from "../../types/WorkPosition";

const UpdatePosition = () => {
  const [workPositionData, setWorkPositionData] = useState({
    title: "",
    description: "",
    city: [] as string[],
    company: "",
    category: "",
  });
  const initialWordPositionData = useRef(workPositionData);
  const [locations, setLocations] = useState<Location[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const positionId = Number(useParams().id);

  useEffect(() => {
    const getSinglePosition = () => {
      setIsLoading(true);

      getPositionById(positionId).then((data) => {
        if (!data) {
          setError("Non è stato possibile caricare la posizione.");
          setIsLoading(false);
        } else {
          const workPositionData = {
            title: data.title,
            description: data.description,
            city: data.locations.map((obj: { id: number }) => `${obj.id}`),
            company: `${data.company_id}`,
            category: `${data.category_id}`,
          };

          setWorkPositionData(workPositionData);
          initialWordPositionData.current = workPositionData;

          setIsLoading(false);
        }
      });
    };

    getSinglePosition();
  }, [positionId]);

  useEffect(() => {
    const getOptions = async () => {
      setIsLoading(true);

      const promises = [
        getLocations().then((data) => {
          if (Array.isArray(data)) {
            setLocations(data);
            return;
          }

          console.log(data.error);
        }),
        getCompanies().then((data) => {
          if (Array.isArray(data)) {
            setCompanies(data);
            return;
          }

          console.log(data.error);
        }),
        getCategories().then((data) => {
          if (Array.isArray(data)) {
            setCategories(data);
            return;
          }

          console.log(data.error);
        }),
      ];

      await Promise.allSettled(promises);
      setIsLoading(false);
    };

    getOptions();
  }, []);

  const getCitiesCheckBoxOptions = (locations: Location[]) =>
    locations.map((location) => ({ id: location.id, value: location.city }));

  const getCompaniesCheckBoxOptions = (companies: Company[]) =>
    companies.map((company) => ({ id: company.id, value: company.name }));

  const getCategoriesCheckBoxOptions = (categories: Category[]) =>
    categories.map((category) => ({ id: category.id, value: category.name }));

  const handleDataChange =
    <Id extends keyof typeof workPositionData>(id: Id) =>
    (value: typeof workPositionData[Id]) =>
      setWorkPositionData((formData) => ({ ...formData, [id]: value }));

  const handleSubmit = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    setIsLoading(true);
    const { city, company, category, title, description } = workPositionData;
    const formattedWorkPositionData = {
      location_ids: city.map(Number),
      company_id: Number(company),
      category_id: Number(category),
      title: title,
      description: description,
    };

    updatePosition(positionId, formattedWorkPositionData).then(
      (data) => {
        const error = Object.values(data).find((value) => Array.isArray(value));

        if (error) {
          setIsLoading(false);
          setError(`${error}`);
        } else {
          setIsLoading(false);
          navigate("/", { replace: true });
        }
      }
    );
  };

  const handleReset = () =>
    setWorkPositionData(initialWordPositionData.current);

  return (
    <>
      <Helmet>
        <title>HVM Dashboard | Aggiorna posizione</title>
      </Helmet>
      {isLoading && (
        <div className="h-full w-full fixed z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <PacmanLoader
            color="#fff"
            loading={isLoading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <div className="container mx-auto py-12">
        <div className="flex items-center justify-between mb-12">
          <h1 className="text-3xl font-semibold leading-10">
            Aggiorna la posizione
          </h1>
          <Link
            to={ROUTES.BASE}
            className="text-base text-indigo font-semibold underline"
          >
            Annulla
          </Link>
        </div>
        <div className="mb-6 flex flex-wrap gap-6">
          <div className="basis-full flex-grow grid grid-cols-2 gap-6">
            <Checkboxes
              id="city"
              label="Città"
              value={workPositionData?.city}
              onChange={handleDataChange("city")}
              options={getCitiesCheckBoxOptions(locations)}
            />
            <RadioGroup
              label="Azienda"
              value={workPositionData?.company}
              onChange={handleDataChange("company")}
              id="company"
              options={getCompaniesCheckBoxOptions(companies)}
            />
          </div>
          <div className="basis-full flex-grow grid grid-cols-2 gap-6">
            <Select
              label="Categoria"
              value={workPositionData?.category}
              onChange={handleDataChange("category")}
              options={getCategoriesCheckBoxOptions(categories)}
              placeholder="Scegliere una categoria"
            />
            <InputText
              label="Titolo"
              value={workPositionData?.title}
              onChange={handleDataChange("title")}
              placeholder="Inserisci un titolo"
            />
          </div>

          <div className="flex-grow">
            <RichTextArea
              label="Descrizione"
              value={workPositionData?.description}
              onChange={handleDataChange("description")}
              placeholder="Inserisci una descrizione"
              id="description"
            />
          </div>
        </div>
        {error && <p className="text-center text-4xl m-2">{error}</p>}
        <div className="flex items-center justify-between">
          <button
            onClick={handleReset}
            className="text-base text-indigo font-semibold underline"
          >
            Reset
          </button>
          <button onClick={handleSubmit} className="btn btn-primary basis-48">
            Aggiorna Posizione
          </button>
        </div>
      </div>
    </>
  );
};

export default UpdatePosition;
