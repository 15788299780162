import React from "react";
import { CheckboxesProps } from "../../types/Input";
import InputBase from "./InputBase";

const Checkboxes: React.FC<CheckboxesProps> = ({
  label,
  helper,
  id: fieldId,
  required,
  options,
  onChange,
  value: values,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const changedId = e.target.value;

    if (values && values.length) {
      const checkedIndex = values.indexOf(changedId);

      if (checkedIndex === -1) {
        onChange([...values, changedId]);
      } else {
        onChange(values.filter((v) => v !== changedId));
      }
    } else {
      onChange([changedId]);
    }
  };

  return (
    <InputBase label={label} helper={helper} id={fieldId}>
      <div className="flex flex-col gap-4">
        {options.map(({ id, value }) => {
          return (
            <div className="bg-white px-4 py-3 border-2 border-ligthGray rounded-lg flex justify-between">
              <div className="flex items-center gap-2 flex-grow cursor-pointer">
                <input
                  className="cursor-pointer w-5 h-5"
                  type="checkbox"
                  value={`${id}`}
                  id={`${value}-${id}`}
                  name={fieldId}
                  required={required}
                  onChange={handleChange}
                  checked={values?.includes(`${id}`)}
                />
                <label
                  className="cursor-pointer w-full"
                  htmlFor={`${value}-${id}`}
                >
                  {value}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </InputBase>
  );
};

export default Checkboxes;
