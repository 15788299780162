import { configureRefreshFetch, fetchJSON } from 'refresh-fetch';

const tokenName = 'jwt';

type Token = {
  access_token: string;
};

function isToken(obj: unknown): obj is Token {
  return typeof obj === 'object' && !!obj && 'access_token' in obj;
}

function retrieveToken(): Token | undefined {
  const token = localStorage.getItem(tokenName);

  if (!token) {
    return undefined;
  }

  try {
    return JSON.parse(token) || undefined;
  } catch (e) {
    return undefined;
  }
}

type TokenCallback = (accessToken?: Token) => void;
const syncTokenCallbacks = new Map<TokenCallback, TokenCallback>();

export function syncToken(cb: TokenCallback) {
  syncTokenCallbacks.set(cb, cb);
  return () => {
    syncTokenCallbacks.delete(cb);
  };
}

function runCallbacks() {
  const token = retrieveToken();
  syncTokenCallbacks.forEach((callbackFn) => callbackFn(token));
}

function saveToken(token: Token) {
  localStorage.setItem(tokenName, JSON.stringify(token));
  runCallbacks();
}

function clearToken() {
  localStorage.removeItem(tokenName);
  runCallbacks();
}

function fetchJSONWithToken<T>(url: string, options?: RequestInit) {
  const token = retrieveToken();

  let optionsWithToken = options;

  if (token != null) {
    optionsWithToken = {
      ...options,
      headers: {
        ...options?.headers,
        Authorization: `Bearer ${token.access_token}`
      }
    };
  }

  return fetchJSON<T>(url, optionsWithToken);
}

const refreshToken = () => {
  return fetchJSONWithToken(`${process.env.REACT_APP_API_URL}/refresh`, {
    method: 'POST'
  })
    .then(response => {
      if (isToken(response.body)) {
        saveToken(response.body);
        return;
      }

      throw new Error('Missing token');
    })
    .catch(error => {
      clearToken()
      throw error
    })
}

export const fetch = configureRefreshFetch({
  fetch: fetchJSONWithToken,
  shouldRefreshToken: (error: { response: Response; }) => error.response.status === 401,
  refreshToken,
});

type UserType = {
  email: string;
  password: string | undefined;
};

export const signin = async (user: UserType) => {
  const res = await fetchJSON<Record<string, string> | null>(`${process.env.REACT_APP_API_URL}/login`, {
    method: "POST",
    body: JSON.stringify(user),
  });

  if (isToken(res.body)) {
    saveToken(res.body);
  }

  return isToken(res.body) ? null : res.body;
};

export const logout = async () => {
  const res = await fetchJSONWithToken(`${process.env.REACT_APP_API_URL}/logout`, {
    method: 'POST'
  });

  clearToken();

  return res.body;
}

export const isAuthenticated = () => {
  const token = retrieveToken();
  return token != null;
};
