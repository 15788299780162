import React from "react";
import { Navigate } from "react-router-dom";

import * as ROUTES from "../../utils/routes";

type PrivateRouteProps = {
  isAuthenticated: boolean;
  children: React.ReactElement;
};

/**
 * PrivateRoute.tsx
 * Handle the redirect if user is not authenticated
 */
export const PrivateRoute = ({ isAuthenticated, children }: PrivateRouteProps) => {
  return isAuthenticated ? children : <Navigate to={ROUTES.LOGIN} />;
};
