import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import * as ROUTES from "../../utils/routes";
import { getPositions, deletePosition } from "../../utils/position";
import { JobPosition } from "../../types/WorkPosition";
import Card from "../../components/Layout/components/Card";
import PacmanLoader from "react-spinners/PacmanLoader";

export const WorkPositions = () => {
  const [positions, setPositions] = useState<JobPosition[]>([]);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAllPositions = () => {
    setIsLoading(true);
    getPositions().then((data) => {
      setIsLoading(false);

      if (Array.isArray(data)) {
        setPositions(data);
        return;
      }

      setError("Non è stato possibile caricare le posizioni aperte.");
    });
  };

  const deleteSinglePosition = (positionId: number) => {
    setIsLoading(true);
    deletePosition(positionId).then((data) => {
      if (data) {
        getAllPositions();
        return;
      }

      setIsLoading(false);
      setError("Non è stato possibile eliminare la posizione.");
    });
  };

  useEffect(() => {
    getAllPositions();
  }, []);

  return (
    <>
      <Helmet>
        <title>HVM Dashboard | Posizioni aperte</title>
      </Helmet>
      {isLoading && (
        <div className="h-full w-full fixed z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <PacmanLoader
            color="#fff"
            loading={isLoading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <div className="container mx-auto py-12">
        <div className="flex justify-between py-8">
          <div>
            <h2 className="mb-4">Posizioni lavorative</h2>
            <h5 className="text-gray">{positions.length} posizioni aperte</h5>
          </div>
          <div>
            <Link to={ROUTES.NEW_POSITION} className="btn btn-primary">
              Crea una Nuova Posizione
            </Link>
          </div>
        </div>
        <div>
          {error && (
            <p className="border-2 py-3 text-center my-0 mx-auto w-[50%]">
              {error}
            </p>
          )}
          <div className="w-full mb-3 flex flex-row">
            <div className="w-[10%]">
              <h5>Data</h5>
            </div>
            <div className="w-[25%]">
              <h5>Città</h5>
            </div>
            <div className="w-[10%]">
              <h5>Azienda</h5>
            </div>
            <div className="w-[15%]">
              <h5>Categoria</h5>
            </div>
            <div className="w-[30%]">
              <h5>Titolo</h5>
            </div>
            <div className="w-[10%]"></div>
          </div>
          <div className="w-full">
            {!!positions.length &&
              positions.map((position: JobPosition) => (
                <Card
                  deletePosition={() => deleteSinglePosition(position.id)}
                  position={position}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
