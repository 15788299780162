import React from "react";
import { RadioGroupProps } from "../../types/Input";
import InputBase from "./InputBase";

const RadioGroup: React.FC<RadioGroupProps> = ({
  label,
  helper,
  id: fieldId,
  required,
  options,
  onChange,
  value: radioValue,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value);

  return (
    <InputBase label={label} helper={helper} id={fieldId}>
      <div className="flex flex-col gap-4">
        {options.map(({ id, value }) => {
          return (
            <div className="bg-white px-4 py-3 border-2 border-ligthGray rounded-lg flex justify-between">
              <div className="flex items-center gap-2 flex-grow cursor-pointer">
                <input
                  className="cursor-pointer w-5 h-5"
                  type="radio"
                  value={`${id}`}
                  id={`${value}-${id}`}
                  name={fieldId}
                  required={required}
                  onChange={handleChange}
                  checked={radioValue === `${id}`}
                />
                <label
                  className="cursor-pointer w-full"
                  htmlFor={`${value}-${id}`}
                >
                  {value}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </InputBase>
  );
};

export default RadioGroup;
