import { CompanySchema, SchemaType } from "../types/CompanySchema";

const defaultSchema: SchemaType[] = [
  { id: "name", label: "Nome e Cognome", type: "text", required: true },
  { id: "role", label: "Ruolo", type: "text", required: true },
];

const companySchema: CompanySchema[] = [
  {
    id: "charitystars.com",
    name: "Charity Stars",
    schema: [
      ...defaultSchema,
      { id: "tel_1", label: "Telefono 1", type: "text", required: false },
      { id: "tel_2", label: "Telefono 2", type: "text", required: false },
      { id: "country", label: "Sede", type: "text", required: false },
      {
        id: "linkedin",
        label: "Linkedin URL",
        type: "text",
        required: false,
      },
    ],
  },
  {
    id: "humanvsmachine.co",
    name: "Human VS Machine",
    schema: [
      ...defaultSchema,
      { id: "tel", label: "Telefono", type: "text", required: false },
      {
        id: "linkedin",
        label: "Linkedin URL",
        type: "text",
        required: false,
      },
    ],
  },
  {
    id: "idagency.it",
    name: "IDA Agency",
    schema: [
      ...defaultSchema,
      { id: "tel", label: "Telefono", type: "text", required: false },
      {
        id: "linkedin",
        label: "Linkedin URL",
        type: "text",
        required: false,
      },
    ],
  },
  {
    id: "ped.academy",
    name: "PED.academy",
    schema: [
      ...defaultSchema,
      { id: "tel", label: "Telefono", type: "text", required: false },
      {
        id: "linkedin",
        label: "Linkedin URL",
        type: "text",
        required: false,
      },
    ],
  },
  {
    id: "ped.company",
    name: "PED.company",
    schema: [
      ...defaultSchema,
      { id: "tel", label: "Telefono", type: "text", required: false },
      {
        id: "linkedin",
        label: "Linkedin URL",
        type: "text",
        required: false,
      },
    ],
  },
  {
    id: "ped.performance",
    name: "PED.performance",
    schema: [
      ...defaultSchema,
      { id: "tel", label: "Telefono", type: "text", required: false },
      {
        id: "linkedin",
        label: "Linkedin URL",
        type: "text",
        required: false,
      },
    ],
  },
  {
    id: "ped.studio",
    name: "PED.studio",
    schema: [
      ...defaultSchema,
      { id: "tel", label: "Telefono", type: "text", required: false },
      {
        id: "linkedin",
        label: "Linkedin URL",
        type: "text",
        required: false,
      },
      {
        id: "dribbble",
        label: "Dribbble",
        type: "text",
        required: false,
      },
    ],
  },
  {
    id: "ped.technology",
    name: "PED.technology",
    schema: [
      ...defaultSchema,
      {
        id: "linkedin",
        label: "Linkedin URL",
        type: "text",
        required: true,
      },
      {
        id: "github",
        label: "Github Name",
        type: "text",
        required: true,
      },
    ],
  },
];

export default companySchema;
