import React from "react";
import { InputProps } from "../../types/Input";
import InputBase from "./InputBase";

const InputText: React.FC<InputProps> = ({
  label,
  placeholder,
  helper,
  id,
  value,
  onChange,
  required,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value);

  return (
    <InputBase label={label} helper={helper} id={id}>
      <input
        id={id}
        placeholder={placeholder}
        className="border-2 px-4 py-3  rounded-lg border-ligthGray"
        type="text"
        value={value ?? ""}
        onChange={handleChange}
        required={required}
      />
    </InputBase>
  );
};

export default InputText;
