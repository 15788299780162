import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PrivateRoute, Layout } from "./components";
import { WorkPositions, SignGenerator, Login } from "./pages";
import { NewPosition } from "./pages/NewPosition";
import UpdatePosition from "./pages/UpdatePosition/UpdatePosition";
import { isAuthenticated, syncToken } from "./utils/auth";

import * as ROUTES from "./utils/routes";

const App = () => {
  const [auth, setAuth] = useState(isAuthenticated);

  useEffect(() => syncToken(() => setAuth(isAuthenticated())), []);

  return (
    <Router>
      <Routes>
        <Route
          path={ROUTES.BASE}
          element={
            <PrivateRoute isAuthenticated={auth}>
              <Layout>
                <WorkPositions />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.SIGN_GENERATOR}
          element={
            <PrivateRoute isAuthenticated={auth}>
              <Layout>
                <SignGenerator />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.NEW_POSITION}
          element={
            <PrivateRoute isAuthenticated={auth}>
              <Layout>
                <NewPosition />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.EDIT_POSITION}
          element={
            <PrivateRoute isAuthenticated={auth}>
              <Layout>
                <UpdatePosition />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.LOGIN}
          element={<Login />}
        />
      </Routes>
    </Router>
  );
};

export default App;
