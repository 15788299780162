import { Link } from "react-router-dom";

import * as ROUTES from "../../../../utils/routes";

// Images
import logoutIcon from "../../../../assets/icons/icon-24-logout.svg";
import logo from "../../../../assets/logo.svg";
import { logout } from "../../../../utils/auth";


/**
 *
 * Header.tsx
 * Component to display the Navigation and User status section
 *
 */
export const Header = () => {

  const handleClickSignout = () => {
    logout();
  };

  return (
    <header className="bg-white">
      <div className="container mx-auto flex justify-between py-6">
        <div>
          <img src={logo} alt="hvm star logo" />
        </div>
        <div className="flex">
          <nav>
            <Link to={ROUTES.BASE} className="mr-4">
              Posizioni Lavorative
            </Link>
            <Link to={ROUTES.SIGN_GENERATOR} className="mr-4">
              Generatore di Firme
            </Link>
          </nav>
          <button onClick={handleClickSignout}>
            <img src={logoutIcon} alt="logout icon" />
          </button>
        </div>
      </div>
    </header>
  );
};
