import { useState } from "react";
import { InputText, Select } from "../../components/Input";

import companySchema from "../../data/companySchema";
import { SignatureData } from "../../types/SignatureData";
import { parseCompanySchemaToOptions } from "../../utils/parseCompanySchemaToOptions";

import downloadIcon from "../../assets/icons/icon-24-download.svg";
import { generateTemplate } from "../../utils/generateTemplate";

import { CompanyEnums } from "../../types/CompanySchema";
import { downloadFile } from "../../utils/downloadFile";
import { Helmet } from "react-helmet";

const companiesList = parseCompanySchemaToOptions(companySchema);

/**
 * SignGenerator.tsx
 * The SignGenerator page
 */
export const SignGenerator = () => {
  const [formData, setFormData] = useState<SignatureData>({});

  const selectedCompany = companySchema.find((c) => c.id === formData.company);

  const handleCompanyChange = (company: string) =>
    setFormData((formData) => ({
      ...formData,
      company: company as CompanyEnums,
    }));

  const handleDataChange = (id: string) => (value: string) =>
    setFormData((formData) => ({ ...formData, [id]: value }));

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const htmlFileToDownload = await generateTemplate(formData);

    if (htmlFileToDownload) {
      downloadFile(
        `${formData.name}-${formData.company}-email_signature.html`,
        htmlFileToDownload
      );
    }
  };

  const areRequiredFilled = selectedCompany?.schema.every(({ id, required }) =>
    required ? formData[id] : true
  );

  return (
    <>
      <Helmet>
        <title>HVM Dashboard | Nuova firma</title>
      </Helmet>
      <div className="container mx-auto py-12">
        <div className="flex justify-center">
          <div>
            <h1 className="mb-8 text-3xl font-semibold leading-10">
              Genera una nuova firma
            </h1>
            <form>
              <div className="grid grid-cols-2 gap-x-6 gap-y-12 justify-center">
                <Select
                  value={formData?.company}
                  onChange={handleCompanyChange}
                  label="Azienda"
                  placeholder="Seleziona un'azienda"
                  options={companiesList}
                />
                {selectedCompany &&
                  selectedCompany.schema.map((v) => (
                    <InputText
                      {...v}
                      value={formData[v.id]}
                      onChange={handleDataChange(v.id)}
                    />
                  ))}
              </div>

              <button
                className="btn btn-primary mt-12 flex items-center gap-2 justify-center transition-opacity disabled:opacity-50"
                onClick={handleSubmit}
                disabled={!areRequiredFilled}
              >
                <img src={downloadIcon} alt="Download icon" />
                Genera Firma e Scarica Codice
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
