const downloadFile = (fileName: string, fileContent: string): void => {
  const anchor = document.createElement("a");
  anchor.style.display = "none";

  document.body.appendChild(anchor);
  anchor.download = fileName;
  anchor.href = fileContent;

  anchor.click();

  anchor.remove();
};

export { downloadFile };
