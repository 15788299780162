import { Category, Company, JobPosition, Location } from "../types/WorkPosition";
import { fetch } from "./auth";

type FetchWorkPosition = {
  location_ids: number[];
  company_id: number;
  category_id: number;
  title: string;
  description: string;
};

export const getPositions = async () => {
  const res = await fetch<{ exception: string; } | JobPosition[]>(`${process.env.REACT_APP_API_URL}/position`);

  return res.body;
};

export const getLocations = async () => {
  const res = await fetch<{ error: string; } | Location[]>(`${process.env.REACT_APP_API_URL}/location`);

  return res.body;
};

export const getCategories = async () => {
  const res = await fetch<{ error: string; } | Category[]>(`${process.env.REACT_APP_API_URL}/category`);

  return res.body;
};

export const getCompanies = async () => {
  const res = await fetch<{ error: string; } | Company[]>(`${process.env.REACT_APP_API_URL}/company`);

  return res.body;
};

export const createPosition = async (position: FetchWorkPosition) => {
  const res = await fetch<Record<string, string[]>>(`${process.env.REACT_APP_API_URL}/position`, {
    method: "POST",
    body: JSON.stringify(position),
  });

  return res.body;
};

export const deletePosition = async (positionId: number) => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/position/${positionId}`, {
    method: "DELETE",
  });

  return res.body;
};

export const getPositionById = async (positionId: number) => {
  const res = await fetch<null | {
    title: string;
    description: string;
    locations: { id: number; }[];
    company_id: number;
    category_id: number;
  }>(`${process.env.REACT_APP_API_URL}/position/${positionId}`);

  return res.body;
};

export const updatePosition = async (
  positionId: number,
  position: FetchWorkPosition
) => {
  const res = await fetch<Record<string, string[]>>(`${process.env.REACT_APP_API_URL}/position/${positionId}`, {
    method: "PATCH",
    body: JSON.stringify(position),
  });

  return res.body;
};
