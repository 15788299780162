import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import logo from "../../assets/logo.svg";
import { isAuthenticated, signin } from "../../utils/auth";

type User = {
  email: string;
  password: string;
};

export const Login = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState<User>({
    email: "",
    password: "",
  });
  const [error, setError] = useState<string>("");

  const handleChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      if (target) {
        setValues({ ...values, [name]: target.value });
      }
    };

  const clickSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    signin(values).then((data) => {
      if (data?.message || data?.email || data?.password) {
        setError("Qualcosa è andato storto, ricontrolla le credenziali");
      } else {
        // navigate("/");
      }
    });
  };

  if (isAuthenticated()) {
    navigate("/", { replace: true });
  }

  return (
    <>
      <Helmet>
        <title>HVM Dashboard | Login</title>
      </Helmet>
      <div className="min-h-screen bg-ice">
        <div className="w-1/3 m-auto h-32 flex justify-center items-center">
          <img src={logo} alt="hvm logo" />
        </div>

        <div className="w-2/5 m-auto bg-white px-24 py-20 rounded-lg">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              value={values.email}
              onChange={handleChange("email")}
              id="email"
              name="email"
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              value={values.password}
              onChange={handleChange("password")}
              id="password"
              name="password"
            />
          </div>

          <button className="btn btn-primary" onClick={clickSubmit}>
            Accedi
          </button>
          {error && (
            <p className="text-red-500 mt-5 text-2xl text-center">{error}</p>
          )}
        </div>
      </div>
    </>
  );
};
